(function () {
  'use strict';

  angular
  .module('neo.public.resultats')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('public.resultats', {
      url: '/resultats/{idPartit}',
     
          templateUrl: 'public/resultats/resultats.tpl.html',
          controller: 'resultatsCtrl2',
          controllerAs: 'vm'
        ,
      resolve:{
       
        resultat: function ( resultats2,$stateParams){
          return resultats2.query({idPartit :  $stateParams.idPartit}).$promise;
    }
      }
    })
            ; }
}());

